import React from 'react';

class TLFCLogo extends React.Component {

  render() {
    return (
        <img src="https://img.icons8.com/ios/50/000000/firewall.png" width="40" height="40" viewBox="0 0 40 40" fill="none"/>
    );
  }
}

export default TLFCLogo;

